export const FormulaModul = [
  {
    id: "1",
    teamNameSm: "Red Bull",
    teamName: "Red Bull Racing",
    engine: "Red Bull Powertrains",
    flag: "/images/flags/12.png",
    teamCode: "A",
    driver1: {
      name: "Max Verstappen",
      no: "1",
      code: "1",
      flag: "/images/flags/15.png",
    },
    driver2: {
      name: "Sergio Perez",
      no: "11",
      code: "2",
      flag: "/images/flags/20.png",
    },
  },
  {
    id: "2",
    teamName: "Mercedes-AMG PETRONAS F1 Team",
    teamNameSm: "Mercedes",
    engine: "Mercedes",
    flag: "/images/flags/12.png",
    teamCode: "B",
    driver1: {
      name: "George Russell",
      no: "63",
      code: "3",
      flag: "/images/flags/12.png",
    },
    driver2: {
      name: "Lewis Hamilton",
      no: "44",
      code: "4",
      flag: "/images/flags/12.png",
    },
  },
  {
    id: "3",
    teamName: "Scuderia Ferrari",
    teamNameSm: "Ferrari",
    engine: "Ferrari",
    flag: "/images/flags/16.png",
    teamCode: "C",
    driver1: {
      name: "Carlos Sainz",
      no: "55",
      code: "5",
      flag: "/images/flags/10.png",
    },
    driver2: {
      name: "Charles Leclerc",
      no: "16",
      code: "6",
      flag: "/images/flags/8.png",
    },
  },
  {
    id: "4",
    teamName: "McLaren Formula 1 Team",
    teamNameSm: "McLaren",
    engine: "Mercedes",
    flag: "/images/flags/12.png",
    teamCode: "D",
    driver1: {
      name: "Lando Norris",
      no: "4",
      code: "7",
      flag: "/images/flags/12.png",
    },
    driver2: {
      name: "Oscar Piastri",
      no: "81",
      code: "8",
      flag: "/images/flags/3.png",
    },
  },
  {
    id: "5",
    teamName: "Aston Martin Aramco F1 Team",
    teamNameSm: "Aston Martin",
    engine: "Mercedes",
    flag: "/images/flags/12.png",
    teamCode: "E",
    driver1: {
      name: "Fernando Alonso",
      no: "14",
      code: "9",
      flag: "/images/flags/10.png",
    },
    driver2: {
      name: "Lance Stroll",
      no: "18",
      code: "10",
      flag: "/images/flags/9.png",
    },
  },
  {
    id: "6",
    teamName: "BWT Alpine F1 Team",
    teamNameSm: "Alpine",
    engine: "Renault",
    flag: "/images/flags/france.png",
    teamCode: "F",
    driver1: {
      name: "Esteban Ocon",
      no: "31",
      code: "11",
      flag: "/images/flags/france.png",
    },
    driver2: {
      name: "Pierre Gasly",
      no: "10",
      code: "12",
      flag: "/images/flags/france.png",
    },
  },
  {
    id: "7",
    teamName: "Williams Racing",
    teamNameSm: "Williams",
    engine: "Mercedes",
    flag: "/images/flags/12.png",
    teamCode: "G",
    driver1: {
      name: "Alexander Albon",
      no: "23",
      code: "13",
      flag: "/images/flags/thailand.png",
    },
    driver2: {
      name: "Franco Colapinto",
      no: "43",
      code: "14",
      flag: "/images/flags/25.png",
    },
  },
  {
    id: "8",
    teamName: "VIsa Cash RB F1 Team",
    teamNameSm: "Racing Bulls",
    engine: "Red Bull Powertrains",
    flag: "/images/flags/11.png",
    teamCode: "H",
    driver1: {
      name: "Liam Lawson",
      no: "30",
      code: "15",
      flag: "/images/flags/26.png",
    },
    driver2: {
      name: "Yuki Tsunoda",
      no: "22",
      code: "16",
      flag: "/images/flags/4.png",
    },
  },
  {
    id: "9",
    teamName: "Stake F1 Team Kick Sauber",
    teamNameSm: "Kick Sauber",
    engine: "Ferrari",
    flag: "/images/flags/switz.png",
    teamCode: "I",
    driver1: {
      name: "Valtteri Bottas",
      no: "77",
      code: "17",
      flag: "/images/flags/finland.png",
    },
    driver2: {
      name: "Zhou Guanyu",
      no: "24",
      code: "18",
      flag: "/images/flags/5.png",
    },
  },
  {
    id: "10",
    teamName: "MoneyGram Haas F1 Team",
    teamNameSm: "Haas",
    engine: "Ferrari",
    flag: "/images/flags/6.png",
    teamCode: "J",
    driver1: {
      name: "Kevin Magnussen",
      no: "20",
      code: "19",
      flag: "/images/flags/danemark.png",
    },
    driver2: {
      name: "Nico Hulkenberg",
      no: "27",
      code: "20",
      flag: "/images/flags/germany.png",
    },
  },
];
